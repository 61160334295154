import {
  Box,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import appImages from "../../constants/AppImage";
import TitleStrip from "../../components/Common/TitleStrip";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import { Autoplay } from "swiper";

const {
  clientLogo: {
    alkemist,
    eurofins,
    gemini,
    grma,
    naturalstacks,
    njlabs,
    sabinsa,
    ansi,
  },
} = appImages;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 2),
    position: "relative",
  },
  gridContainer: {
    justifyContent: "center",
  },
  logoItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  },
  logo: {
    maxWidth: "100%",
    height: "150px",
    objectFit: "contain",
  },
}));

const OurClient = () => {
  const classes = useStyles();
  const theme = useTheme();

  console.log(useMediaQuery(theme.breakpoints.down("sm")));
  // Partner logos array
  const match = !useMediaQuery(theme.breakpoints.down("sm"));
  const partners = [
    { name: "Alkemist", logo: alkemist },
    // { name: "Eurofins", logo: eurofins },
    { name: "Gemini", logo: gemini },
    { name: "NJ Labs", logo: njlabs },
    { name: "GRMA", logo: grma },
    { name: "Natural Stacks", logo: naturalstacks },
    { name: "ANSI", logo: ansi },
    // { name: "Sabinsa", logo: sabinsa },
  ];

  return (
    <Grid className={classes.root}>
      <TitleStrip title="Our Clients" />

      {/* <Box className="bodyMaxWidth">
        <Grid container spacing={4} className={classes.gridContainer}>
          {partners.map((partner) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              key={partner.name}
              className={classes.logoItem}
            >
              <img
                src={partner.logo}
                alt={`${partner.name} logo`}
                className={classes.logo}
              />
            </Grid>
          ))}
        </Grid>
      </Box> */}
      <div style={{ margin: "50px 0px" }}>
        <Swiper
          spaceBetween={10}
          slidesPerView={match ? 4 : 1}
          autoplay={{ delay: 1500, disableOnInteraction: false }}
          modules={[Autoplay]}
          loop={true}
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          {partners.map((data, index) => (
            <SwiperSlide
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={data.logo}
                className={classes.logo}
                width="300px"
                height="80px"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Grid>
  );
};

export default OurClient;
