import AsSeenInSection from "../../components/Common/AsSeenInSection";
import Footer from "../../components/Footer";
import HomePage from "../../components/NewHomePageDesign/HomePage";
import DocumentProtection from "./DocumentProtection";
import Feature from "./Feature";
import HighLight from "./HighLight";
import NewFooter from "./NewFooter";
import NewInsights from "./NewInsights";
import OurClient from "./OurClient";
import Partner from "./Partner";
import UnToldStories from "./UnToldStories";
import WhoWeArePage from "./WhoWeArePage";

const NewHomePageDesign = () => {
  return (
    <>
      <HomePage />
      {/* <HighLight /> */}
      <OurClient />
      <DocumentProtection />
      <WhoWeArePage />
      {/* <UnToldStories /> */}
      <NewInsights />
      <Partner />
      <AsSeenInSection />
      {/* <Footer /> */}
      <NewFooter />
    </>
  );
};

export default NewHomePageDesign;
