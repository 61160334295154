import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import appImages from "../../constants/AppImage";
import TitleStrip from "../../components/Common/TitleStrip";

const {
  newHomePageDesignImage,
  newHomePage: { map, timeLine },
} = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    backgroundImage: `url(${newHomePageDesignImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "repeat-y",
    backgroundPosition: "bottom center",
    animation: "$moveBackground 5s linear infinite",
  },
  "@keyframes moveBackground": {
    "0%": { backgroundPositionY: "0%" },
    "100%": { backgroundPositionY: "100%" },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.3)",
  },
  content: {
    position: "relative",
    zIndex: 1,
    color: "#fff",
    padding: "100px",
    "&>h1": {
      fontSize: "50px",
      fontWeight: 600,
      color: "#fff",
      width: "50%",
      [theme.breakpoints.down("md")]: {
        width: "80%",
        fontSize: "30px",
        marginTop: "25px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        fontSize: "30px",
      },
    },

    [theme.breakpoints.down("sm")]: {
      padding: "50px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    "&>a": {
      marginTop: "50px",
    },
  },
  showProductContainer: {
    padding: "0px 30px",
    position: "relative",
  },
  exampleDocument: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "50px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "20px",
      alignItems: "center",
    },
  },
  documentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
  },

  documentImage: {
    width: "100%",
    height: "400px",
    objectFit: "contain",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      maxHeight: "500px",
    },
  },

  documentDescription: {
    color: theme.palette.common.white,
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "10px",
    textAlign: "center",
    padding: "15px",
    borderRadius: "8px",
    fontStyle: "italic",
  },
  newButton: theme.overrides.MuiButton.newButton,
}));

const WhoWeArePage = () => {
  const classes = useStyle();

  return (
    <Box className={classes.root}>
      <div className={classes.overlay}></div>
      <TitleStrip title="Product Origin" />
      <Box className={classes.content}>
        <Typography variant="h1">
          What is the truth about the product you use ?
        </Typography>
        <Button href="/product-origin" className={classes.newButton}>Learn more</Button>
      </Box>

      <Box className={classes.showProductContainer}>
        <Grid container spacing={3} className={classes.exampleDocument}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.documentBox}>
              <img src={map} alt="Map" className={classes.documentImage} />
              <Typography className={classes.documentDescription}>
                HealthLOQ captures first-party inputs from ingredient suppliers
                and manufacturers and knits that information together into a
                product genealogy. It links supply chain information from
                specific ingredient batches to the specific batch of the final
                product.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Box className={classes.documentBox}>
              <img
                src={timeLine}
                alt="Timeline"
                className={classes.documentImage}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default WhoWeArePage;
