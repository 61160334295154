import {
  Box,
  Link,
  makeStyles,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import { AppImages } from "../../constants";
import PrimaryHeading from "../../components/Common/Typography/PrimaryHeading";
import TitleStrip from "../../components/Common/TitleStrip";

const {
  affiliateLogo: { logoBig, logoFoodchain, logoRhema, logoUl },
} = AppImages;

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    "&>h1": {
      display: "flex",
      justifyContent: "center",
      margin: "50px 0px",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    gap: 50,
    marginBottom: "50px",
    paddingTop : "100px",
    [theme.breakpoints.down("md")]: {
      gap: 30,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: 50,
    },
  },
  logo: {
    maxWidth: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
  },
}));

const data = [
  {
    logo: logoUl,
    link: "https://www.ul.com/industries/products-and-components/otc-and-dietary-supplements",
  },
  {
    logo: logoFoodchain,
    link: "https://www.foodchainid.com/testing/digital-pcr-for-gmo-testing/",
  },
  {
    logo: logoRhema,
    link: "https://rhemamade.com/",
  },
  {
    logo: logoBig,
    link: "https://www.nutriient.biz/",
  },
];

const Partner = () => {
  const classes = useStyle();
  return (
    <Box className={classes.root}>
      <TitleStrip title="Our Partners" />
      <Box className={classes.container}>
        {data.map((item) => (
          <Link href={item.link} target="_blank" key={item.link}>
            <img src={item.logo} alt="Partner Logo" className={classes.logo} />
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default Partner;
