const appImages = {
  logo: "/assets/images/logo/healthloq.png",
  logoCRN: "/assets/images/logo/CRN-member.png",
  logoProud: "/assets/images/logo/AHPA2.png",
  logoNPM: "/assets/images/logo/npm.png",
  grma_image: "/assets/images/grma-1.png",
  grma_page: "/assets/images/grma_page.png",
  newHomePageDesignImage: "/assets/images/test.png",
  documentProtectionBackGroundImage: "/assets/images/documentProtection.png",
  affiliateLogo: {
    logoBig: "/assets/images/logo/nutrlient.png",
    logoFoodchain: "/assets/images/logo/foodchain.png",
    logoRhema: "/assets/images/logo/rhema.png",
    logoUl: "/assets/images/logo/ul.png",
  },
  homePageImg: {
    whyHealthLoq: "/assets/images/why_healthloq.png",
    blockchainHelp: "/assets/images/blockchain_help.png",
    bgHome: "/assets/images/bg-home-landscape.jpg",
    featuredUi01: "/assets/images/featured-ui-1.png",
    featuredUi02: "/assets/images/featured-ui-2.png",
    featuredUi03: "/assets/images/featured-ui-3.png",
    featuredCompaniesBlack: "/assets/images/featured-companies-black.jpg",
    featuredCompanies01: "/assets/images/logo/img-logo03.png",
    featuredCompanies02: "/assets/images/logo/img-logo02.png",
    featuredCompanies03: "/assets/images/logo/img-logo04.png",

    featuredCompanies04: "/assets/images/logo/betterbrand.png",
    featuredCompanies05: "/assets/images/logo/nutriient.png",
    featuredCompanies06: "/assets/images/logo/natural-stacks.png",
    featuredCompanies07: "/assets/images/logo/brilliant-health.png",
    featuredCompanies08: "/assets/images/logo/ex-citing-tracts.png",

    easyImg: "/assets/images/easy.png",
    secureImg: "/assets/images/secure.png",
    trustedImg: "/assets/images/trusted.png",
    screenshortHomeImg: "/assets/images/screenshort_home.png",
  },
  asSeenInImg: {
    asSeenInImg01: "/assets/images/as-seen-in/vitafoods.png",
    asSeenInImg02: "/assets/images/as-seen-in/organicandnatural.jpg",
    asSeenInImg03: "/assets/images/as-seen-in/goed-logo.svg",
    asSeenInImg04: "/assets/images/as-seen-in/CRN-member.png",
    asSeenInImg05: "/assets/images/as-seen-in/AHPA2.png",
    asSeenInImg06: "/assets/images/as-seen-in/npm.png",
    asSeenInImg07: "/assets/images/as-seen-in/foodingredientslogo.png",
  },
  icon: {
    notification: "/assets/images/icon/icon-notification.svg",
    right: "/assets/images/icon/icon-right.svg",
    download: "/assets/images/icon/icon-download.svg",
    folderIcon: "/assets/images/icon/icon-folder.svg",
    buyIcon: "/assets/images/icon/icon-buy.svg",
    homeIcon: "/assets/images/icon/icon-home.svg",
    diversityIcon: "/assets/images/icon/icon-diversity.jpg",
    qualityIcon: "/assets/images/icon/icon-quality.jpg",
    sustainabilityIcon: "/assets/images/icon/icon-sustainability.jpg",
    Icon404: "/assets/images/icon/icon-404.svg",
    batchIcon: "/assets/images/icon/batch.png",
    blockchainIcon: "/assets/images/icon/blockchain-proof.png",
    blockchainwhiteIcon: "/assets/images/icon/blockchain-proof-white.png",
    iconRight: "/assets/images/icon/icon-right.png",
    iconView: "/assets/images/icon/view.svg",
    iconReplay: "/assets/images/icon/icon-replay.svg",
    iconClose: "/assets/images/icon/icon-close.svg",
    searchIcon: "/assets/images/icon/Search.svg",
    usEnglishIcon: "/assets/images/icon/Us English.svg",
    spanishIcon: "/assets/images/icon/Spanish.svg",
    frenchIcon: "/assets/images/icon/French.svg",
    linkIcon: "/assets/images/icon/icon-link.svg",
    linkedinIcon: "/assets/images/icon/linkedin.svg",
    uploadIcon: "/assets/images/icon/icon-upload.svg",
    correctIcon: "/assets/images/icon/icon-correct.png",
    wrongIcon: "/assets/images/icon/icon-wrong.png",
    greaterThanIcon: "/assets/images/icon/greaterthan-icon.svg",
    documentIcon: "/assets/images/icon/document-icon.svg",
    blockchainProofIcon: "/assets/images/icon/blockchain_proof-icon.svg",
    iButtonIcon: "/assets/images/icon/i-button-icon.svg",
  },
  logos: {
    certificateLogo: `/assets/images/logo/certificateLogo.jpg`,
  },
  product: {
    productHeaderImg: "/assets/images/background-green.jpg",
  },
  loader: "/assets/images/loader.gif",
  imageNoItem: "/assets/images/image-no-item.png",
  imageTC: "/assets/images/background-tc.jpg",
  homeVideo: "/assets/video/homepage_video.mp4",
  homeVideoPoster: "/assets/video/homepage_video_poster_gif.gif",
  images: {
    impressionHeader: "/assets/images/impression-header.png",
    founder1: "/assets/images/derek-lurth.jpeg",
    founder2: "/assets/images/ken-staker.jpeg",
    founder3: "/assets/images/todd-taylor.jpeg",
    advisor1: "/assets/images/thomas-sullivan.jpeg",
    advisor2: "/assets/images/kenn-lsrael.jpeg",
    advisor3: "/assets/images/craig-miles.jpeg",
    advisor4: "/assets/images/robert-pos.jpeg",
    advisor5: "/assets/images/rakesh-amin.jpeg",
    advisor6: "/assets/images/norman-relkin.jpeg",
    oAdteam1: "/assets/images/sanjay-panchal.jpeg",
    oAdteam2: "/assets/images/ronak.jpeg",
    oAdteam3: "/assets/images/mckay-tucker.jpeg",
    sectionBottomUIImage: "/assets/images/section-bottom-ui.png",
    solutionsForIncluding: "/assets/images/solutions-for-including.png",
    solutionsSlideShowImage: "/assets/images/solutions.png",
    cpSection4Image: "/assets/images/counterfeit-prevention-section4.png",
    cpSection3Image: "/assets/images/counterfeit-prevention-section3.png",
    supplierHowItWorkSection1: "/assets/images/suppierHowItWorkSection1.png",
    supplierHowItWorkSection2: "/assets/images/supplierHowItWorkSection2.png",
    supplierValidDocument: "/assets/images/supplierValidDocumentExample.png",
    supplierFraudulentDocumentWorks:
      "/assets/images/fraudulentDocumentWorks.png",
    fraudulentDocumentExample: "/assets/images/fraudulentDocumentExample.png",
    bluckAuthentication: "/assets/images/bluckAuthentication.png",
    bulkVerification: "/assets/images/bulkVerification.png",
    verificationDashboard: "/assets/images/verificationDashboard.png",
    supplierHeaderImg: "/assets/images/supplierHeaderImg.png",
    connectToConsumer: "/assets/images/connectToConsumer.png",
    ConsumerProfileImg: "/assets/images/ConsumerProfile.png",
    consumerHealthloqSection1Img:
      "/assets/images/consumerHealthloqSection1.png",
    consumerHealthloqSection2Img:
      "/assets/images/consumerHealthloqSection2.png",
    labelSection1Img: "/assets/images/labelSection1.png",
    productTreakHeader: "/assets/images/productTreakHeader.png",
    consumerConnectionHeader: "/assets/images/consumerConnectionHeader.png",
    labelLegitimizationHeader: "/assets/images/labelLegitimizationHeader.png",
    circularEconomyHeader: "/assets/images/circularEconomyHeader.png",
    retailersHeader: "/assets/images/retailersHeader.png",
    manufacturerBrandsHeader: "/assets/images/manufacturerBrandsHeader.png",
    labsHeaders: "/assets/images/labsHeaders.png",
    ingradinatSuppliersHeader: "/assets/images/ingradinatSuppliersHeader.png",
    solutionsHeader: "/assets/images/solutionsHeader.png",
    profileIcon: "/assets/images/profileIcon.png",
    consumerHeader: "/assets/images/consumerHeader.png",
    counterfeitPrevention: "/assets/images/counterfeitPrevention.png",
    feedBackImg: "/assets/images/feedBackImg.png",
    validCoa: "/assets/images/validCoa.png",
    validCoaBlockchainProof: "/assets/images/validCoaBlockchainProof.png",
    invalidCoa: "/assets/images/invalidCoa.png",
    invalidCoaBlockchainProof: "/assets/images/invalidCoaBlockchainProof.png",
    docAuthenticatorDashboard: "/assets/images/docAuthenticatorDashboard.png",
    bulkDocVerification: "/assets/images/bulkDocVerification.png",
    widgetVerification: "/assets/images/widgetVerification.png",
    bulkFilesIcon: "/assets/images/bulkFilesIcon.png",
    docFolderForBulkVerification:
      "/assets/images/docFolderForBulkVerification.png",
    counterfeitPreventionImage: "/assets/images/counterfeitPreventionImage.png",
    productMapboxImage: "/assets/images/productMapboxImage.png",
    consumerProfileImage: "/assets/images/consumerProfileImage.png",
    consumerHomePageImage: "/assets/images/consumerHomePageImage.png",
    productFeedbackFormImage: "/assets/images/productFeedbackFormImage.png",
    ingredientComparisionImage: "/assets/images/ingredientComparisionImage.png",
    productListPageImage: "/assets/images/productListPageImage.png",
    ComplianceDashboardImage: "/assets/images/complaince_dashboard.png",
  },
  profileImages: {
    profileBg: "/assets/images/profile_bg.png",
    createProfileImage: "/assets/images/create-profile-icon.png",
    createProfileFemaleImage: "/assets/images/create-female-icon.png",
    createProfileMaleImage: "/assets/images/create-male-icon.png",
    createProfileMaleImage: "/assets/images/create-male-icon.png",
  },
  headerImages: {
    circularEconomy: "/assets/images/headerImages/circularEconomy.png",
    consumerConnections: "/assets/images/headerImages/consumerConnections.png",
    consumers: "/assets/images/headerImages/consumers.png",
    counterfeitPrevention:
      "/assets/images/headerImages/counterfeitPrevention.png",
    labelLegitimization: "/assets/images/headerImages/labelLegitimization.jpg",
    documentProtection: "/assets/images/headerImages/documentProtectionImg.png",
    manufacturarBrands: "/assets/images/headerImages/manufacturarBrands.png",
    productOrigin: "/assets/images/headerImages/productOrigin.png",
    solutions: "/assets/images/headerImages/solutions.png",
    documentVerification:
      "/assets/images/headerImages/documentVerification.png",
    imageShadow: "/assets/images/headerImages/imageShadow.png",
    imageShadowRight: "/assets/images/headerImages/imageShadowRight.png",
    newHomePage: "/assets/images/headerImages/new-home-page.jpg",
  },
  grama_intiegrity_program: {
    adora: "/assets/images/grmaPartnerLogo/Adora.png",
    alkemist: "/assets/images/grmaPartnerLogo/Alkemist.jpg",
    cas: "/assets/images/grmaPartnerLogo/CAS.png",
    chpa: "/assets/images/grmaPartnerLogo/CHPA.png",
    delta: "/assets/images/grmaPartnerLogo/Delta.jpg",
    dollarTreeFamily: "/assets/images/grmaPartnerLogo/Dollar-Tree-Family.png",
    eurofinsAssurance: "/assets/images/grmaPartnerLogo/eurofins_Assurance.png",
    excelsiorAlchemy: "/assets/images/grmaPartnerLogo/Excelsior-Alchemy.jpg",
    firstQuality: "/assets/images/grmaPartnerLogo/FirstQuality.jpg",
    gaiaHerbs: "/assets/images/grmaPartnerLogo/GaiaHerbs.png",
    garcoa: "/assets/images/grmaPartnerLogo/Garcoa.png",
    geminiPharm: "/assets/images/grmaPartnerLogo/GeminiPharm.png",
    goed: "/assets/images/grmaPartnerLogo/goed.png",
    gol: "/assets/images/grmaPartnerLogo/GOL.png",
    heb: "/assets/images/grmaPartnerLogo/HEB.jpg",
    hMaster: "/assets/images/grmaPartnerLogo/H_Master.png",
    jitExperts: "/assets/images/grmaPartnerLogo/JITExperts.png",
    kroger: "/assets/images/grmaPartnerLogo/kroger.png",
    le: "/assets/images/grmaPartnerLogo/LE.png",
    naturesWay: "/assets/images/grmaPartnerLogo/NaturesWay.png",
    nsf: "/assets/images/grmaPartnerLogo/nsf.png",
    ohm: "/assets/images/grmaPartnerLogo/Ohm.jpg",
    pharmavite: "/assets/images/grmaPartnerLogo/Pharmavite.jpg",
    rbs: "/assets/images/grmaPartnerLogo/RBS.jpg",
    reenaGroup: "/assets/images/grmaPartnerLogo/ReenaGroup.png",
    rockline: "/assets/images/grmaPartnerLogo/Rockline.jpg",
    tarored: "/assets/images/grmaPartnerLogo/TARORED.jpg",
    thecoresolution: "/assets/images/grmaPartnerLogo/thecoresolution.jpg",
    tic: "/assets/images/grmaPartnerLogo/tic.png",
    tmr: "/assets/images/grmaPartnerLogo/TMR.png",
    topco: "/assets/images/grmaPartnerLogo/Topco.jpg",
    towerLabs: "/assets/images/grmaPartnerLogo/TowerLabs.jpg",
    unpa: "/assets/images/grmaPartnerLogo/UNPA.png",
    vitacost: "/assets/images/grmaPartnerLogo/Vitacost.png",
    wegmans: "/assets/images/grmaPartnerLogo/Wegmans.jpg",
    wfm: "/assets/images/grmaPartnerLogo/WFM.jpg",
    yeLogoColor: "/assets/images/grmaPartnerLogo/YELogoColor.png",
    yerbaPrima: "/assets/images/grmaPartnerLogo/YerbaPrima.png",
    youtheory: "/assets/images/grmaPartnerLogo/Youtheory.png",
  },
  clientLogo: {
    alkemist: "/assets/images/clientLogo/alkemist.png",
    eurofins: "/assets/images/clientLogo/eurofins.png",
    gemini: "/assets/images/clientLogo/gemini.png",
    grma: "/assets/images/clientLogo/grma.png",
    naturalstacks: "/assets/images/clientLogo/naturalstacks.jpeg",
    njlabs: "/assets/images/clientLogo/njlabs.png",
    sabinsa: "/assets/images/clientLogo/sabinsa.png",
    ansi: "/assets/images/clientLogo/ansi.png",
  },
  newHomePage: {
    document1: "/assets/images/newHomePage/document-1.png",
    document2: "/assets/images/newHomePage/document-2.png",
    inValidDocument: "/assets/images/newHomePage/inValidDocument.png",
    validDocument: "/assets/images/newHomePage/validDocument.png",
    documentVerification: "/assets/images/newHomePage/documentVerification.png",
    map: "/assets/images/newHomePage/map.png",
    timeLine: "/assets/images/newHomePage/timeLine.png",
  },
};
export default appImages;
