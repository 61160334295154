import { Grid, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  blackStripe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "150px",
    height: "50px",
    minWidth: "400px",
    backgroundColor: theme.palette.common.black,
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    marginLeft: "50px",
    display: "flex",
    textAlign: "center",
    justifyContent: "start",
    color: theme.palette.common.white,
    alignItems: "center",
    fontSize: "25px",
    paddingLeft: "25px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
      minWidth: "fit-content",
    },
  },
}));

const TitleStrip = ({ title = "Example" }) => {
  const classes = useStyle();

  return <Grid className={classes.blackStripe}>{title}</Grid>;
};

export default TitleStrip;
