import {
  Box,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import appImages from "../../constants/AppImage";

const { logo } = appImages;
const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor : "#BFBFBF",
    padding: "50px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
      gap: "35px",
    },
  },
  footerHeader: {
    color: "#000",
    fontWeight: "bolder",
    marginBottom: "25px",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    cursor: "pointer",
  },
  newButton: theme.overrides.MuiButton.newButton,
  footerCopyRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "10px 15px",
    "&>p": {
      color: theme.palette.common.white,
      textAlign: "center",
    },
  },
}));

const menuList = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Service",
    link: "/",
  },
  {
    name: "About",
    link: "/",
  },
  {
    name: "Materials",
    link: "/",
  },
  {
    name: "Resources",
    link: "/",
  },
  {
    name: "Contact",
    link: "/",
  },
  {
    name: "FAQ",
    link: "/",
  },
];

const socialLink = [
  {
    name: "Facebook",
    link: "/",
  },
  {
    name: "Twitter",
    link: "/",
  },
  {
    name: "Linkedin",
    link: "/",
  },
];

const NewFooter = () => {
  const classes = useStyle();
  return (
    <>
      <Grid className={classes.root}>
        <Grid>
          <Typography className={classes.footerHeader}>Contact</Typography>
          <Typography style={{ marginBottom: "20px" }}>
            1255 E Baseline Road, Suite B260,
            <br /> Mesa, AZ 85202
          </Typography>
          {/* <Grid> */}
          <img src={logo} />
          {/* </Grid> */}
        </Grid>
        <Grid>
          <Typography className={classes.footerHeader}>Be in know</Typography>
          <Typography style={{ marginBottom: "20px" }}>
            I'm a paragraph. Click here to add your own text and edit me.
            <br /> Let your users get to know you.
          </Typography>
          <Grid>
            <TextField variant="standard" placeholder="Enter your email" />
            <Button className={classes.newButton}>Submit</Button>
          </Grid>
        </Grid>
        <Grid>
          <Typography className={classes.footerHeader}>Menu</Typography>
          <Grid className={classes.list}>
            {menuList.map((list) => (
              <Link>{list.name}</Link>
            ))}
          </Grid>
        </Grid>
        <Grid>
          <Typography className={classes.footerHeader}>Follow on us</Typography>
          <Grid className={classes.list}>
            {socialLink.map((list) => (
              <Link>{list.name}</Link>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.footerCopyRight}>
        <Typography variant="body2">
          © Copyright {new Date().getFullYear()} HealthLOQ LLC. All rights
          reserved
        </Typography>
      </Box>
    </>
  );
};

export default NewFooter;
