import { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import appImages from "../../constants/AppImage";
import SimpleSelect from "../../components/Common/FormComponents/SimpleSelect";
import { AppConstant } from "../../constants";
import CalendlyPage from "../../components/Common/CalendlyPage";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";

const { header } = AppConstant;

const {
  headerImages: { newHomePage },
  logo,
} = appImages;

const defaultState = {
  Solutions: "",
  Partners: "",
  "Trusted Products & Documents": "",
  Consumers: "",
  "About Us": "",
};

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "auto",
    minHeight: "100vh",
    backgroundImage: `url(${newHomePage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 60px",
  },
  headerLinks: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    "&>ul": {
      "&>div": {
        "&>div": {
          "&>span": {
            color: "#fff",
          },
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  headerRightButtons: {
    display: "flex",
    alignItems: "center",
    "& button": {
      "&:first-child": {
        marginRight: 15,
        "&>span": {
          "&>svg": {
            width: 32,
            height: 32,
          },
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  userIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      "&>span": {
        "&>svg": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  registerOrLoginText: {
    marginRight: 15,
    marginTop: 15,
    display: "flex",
    alignItems: "center",
  },
  contentSection: {
    "&>div": {
      padding: "150px 280px",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        padding: "0px",
      },
      "&>h1": {
        color: "#fff",
        fontSize: "80px",
        fontWeight: 600,
        marginBottom: "20px",
        [theme.breakpoints.down("md")]: {
          fontSize: "50px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
        },
      },
      "&>h5": {
        color: "#fff",
      },
      "&>div": {
        marginTop: "50px",
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          padding: "0px 30px",
        },
      },
    },
  },
  typewriter: {
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    animation: `$typing 2s steps(80, end)`,
    [theme.breakpoints.down("sm")]: {
      animation: "none",
      whiteSpace: "wrap",
    },
  },
  "@keyframes typing": {
    "0%": { width: 0 },
    "100%": { width: "100%" },
  },
  newButton: theme.overrides.MuiButton.newButton,
  menuIcon: {
    display: "none",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  headerContent: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&>a": {
        "&>img": {
          width: "75px",
          height: "75px",
        },
      },
    },
  },
  mobileMenu: {
    display: "none",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  menuButton: {
    display: "grid",
    justifyItems: "center",
  },
  mobileMenuContainer: {
    display: "grid",
    minWidth: "230px",
    marginTop: "25px",
    gap: "18px",
    marginBottom: "30px",
    justifyItems: "center",
  },
  userIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      "&>span": {
        "&>svg": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
}));
const HomePage = () => {
  const classes = useStyle();
  const history = useHistory();

  const [state, setState] = useState(defaultState);
  const [userIconAnchorEl, setUserIconAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleSelect = (key, value) => {
    setState({ ...defaultState, [key]: value });
    history.push(value);
  };
  return (
    <>
      <Box className={classes.root}>
        {/* -------------- HEADER SECTION START -------------- */}
        <Box className={classes.header}>
          {/* --------- LOGO ------------- */}
          {/* Add home page route */}
          <Box className={classes.headerContent}>
            <Link>
              <img width="75px" height="75px" src={logo} alt="HealthLOQ Logo" />
            </Link>
            <Grid
              onClick={() => setOpenMenu(!openMenu)}
              className={classes.menuIcon}
            >
              <MenuIcon color="primary" />
            </Grid>
          </Box>

          {/* ------------ MENU SECTION ---------------- */}
          <Box className={classes.headerLinks} gridColumnGap={6}>
            <SimpleSelect
              value={state.Solutions}
              onChange={(value) => handleSelect("Solutions", value)}
              options={header.Solutions}
              label="Solutions"
            />
            <SimpleSelect
              value={state.Partners}
              onChange={(value) => handleSelect("Partners", value)}
              options={header.Partners}
              label="Partners"
            />
            <SimpleSelect
              value={state["Trusted Products & Documents"]}
              onChange={(value) =>
                handleSelect("Trusted Products & Documents", value)
              }
              options={header["Trusted Products & Documents"]}
              label="Trusted Products & Documents"
            />
            <SimpleSelect
              value={state.Consumers}
              onChange={(value) => handleSelect("Consumers", value)}
              options={header.Consumers}
              label="Consumers"
            />
            <SimpleSelect
              value={state["About Us"]}
              onChange={(value) => handleSelect("About Us", value)}
              options={header["About Us"]}
              label="About Us"
            />
          </Box>

          {/* ------------ ICON ------------ */}
          <Box className={classes.headerRightButtons}>
            <IconButton
              className={classes.userIcon}
              onClick={(e) => setUserIconAnchorEl(e.currentTarget)}
            >
              <AccountCircleIcon />
            </IconButton>
            <CalendlyPage>
              <Button className={classes.newButton}>Request a Demo</Button>
            </CalendlyPage>
          </Box>
        </Box>
        {/* -------------- HEADER SECTION END -------------- */}

        {/* -------------- CONTENT START --------------  */}

        <Box className={classes.contentSection}>
          <Box>
            <Typography variant="h1" className={classes.typewriter}>
              Transparency increases trust.
            </Typography>
            <Typography variant="h5">
              Blockchain-based trust across the supply chain
            </Typography>
            <Box>
              <Button href="/verify-document" className={classes.newButton}>
                Verify Document
              </Button>
              <Button
                href="/products?product_type=all"
                className={classes.newButton}
              >
                Verify Product Origin
              </Button>
            </Box>
          </Box>
        </Box>
        {/* -------------- CONTENT END --------------  */}

        <Drawer
          variant="temporary"
          anchor="right"
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          ModalProps={{ keepMounted: true }}
          className={classes.mobileMenu}
        >
          <CloseIcon
            onClick={() => setOpenMenu(false)}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: 0,
            }}
          />
          <Grid className={classes.mobileMenuContainer}>
            <Link href="/">
              <img src={logo} width={50} alt="HealthLOQ Logo" />
            </Link>
            <SimpleSelect
              value={state.Solutions}
              onChange={(value) => handleSelect("Solutions", value)}
              options={header.Solutions}
              label="Solutions"
            />
            <SimpleSelect
              value={state.Partners}
              onChange={(value) => handleSelect("Partners", value)}
              options={header.Partners}
              label="Partners"
            />
            <SimpleSelect
              value={state["Trusted Products & Documents"]}
              onChange={(value) =>
                handleSelect("Trusted Products & Documents", value)
              }
              options={header["Trusted Products & Documents"]}
              label="Trusted Products & Documents"
            />
            <SimpleSelect
              value={state.Consumers}
              onChange={(value) => handleSelect("Consumers", value)}
              options={header.Consumers}
              label="Consumers"
            />
            <SimpleSelect
              value={state["About Us"]}
              onChange={(value) => handleSelect("About Us", value)}
              options={header["About Us"]}
              label="About Us"
            />
          </Grid>
          <Grid className={classes.menuButton}>
            <IconButton
              style={{ width: 50, marginBottom: 5 }}
              className={classes.userIcon}
              onClick={(e) => setUserIconAnchorEl(e.currentTarget)}
            >
              <AccountCircleIcon />
            </IconButton>
            <Typography variant="body1" style={{ marginBottom: 5 }}>
              <Link href="/register">Register</Link>&nbsp;or&nbsp;
              <Link href="/login">Login</Link>
            </Typography>
            <CalendlyPage>
              <Button style={{ fontSize: 10 }}>Request a Demo</Button>
            </CalendlyPage>
          </Grid>
        </Drawer>
        {/* ------------- REGISTER & LOGIN DROPDOWN ------------- */}
        <Menu
          anchorEl={userIconAnchorEl}
          keepMounted
          open={Boolean(userIconAnchorEl)}
          onClose={() => setUserIconAnchorEl(null)}
        >
          <MenuItem
            onClick={(event) => {
              setUserIconAnchorEl(null);
              history.push("/login");
            }}
          >
            Login
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              setUserIconAnchorEl(null);
              history.push("/register");
            }}
          >
            Register
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default HomePage;
