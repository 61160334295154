import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import PageNotFound from "../containers/PageNotFound";
import ContactUs from "../containers/ContactUs";
import Products from "../containers/Products";
import ProductDetailV5 from "../containers/Products/ProductDetailV5";
import TermsAndConditions from "../containers/TermsAndConditions";
import Privacy from "../containers/Privacy";
import SubscriptionCancellation from "../containers/SubscriptionCancellation";
import ScrollToTop from "./ScrollToTop";
import ProductList from "../containers/productList";
import Affiliate from "../containers/Affiliate";
import Partners from "../containers/Partners";
import Authenticator from "../containers/Authenticator";
import BlogNews from "../containers/BlogNews";
import BlogNewsDetail from "../containers/BlogNews/BlogNewsDetail";
import Profile from "../containers/Profile";
import { connect } from "react-redux";
import { getUserDetails } from "../redux/actions/profileActions";
import utils, { verifyEnvironment } from "../utils/commonUtils";
import { useLocation, useHistory } from "react-router-dom";
import { Signup, Login } from "../containers/Auth";
import TrackAndTrace from "../containers/TrackAndTrace";
import OrganizationDetail from "../containers/organization/OrganizationDetail";
import { Socket } from "../components/Common";
import Solutions from "../containers/Solutions";
import CounterfeitPrevention from "../containers/CounterfeitPrevention";
import DocumentVerification from "../containers/DocumentVerification";
import ConsumerConnection from "../containers/ConsumerConnection";
import LabelLegitimization from "../containers/LabelLegitimization";
import ProductOrigin from "../containers/ProductOrigin";
import CircularEconomy from "../containers/CircularEconomy";
import Retailers from "../containers/Retailers";
import ManufacturerBrands from "../containers/ManufacturerBrands";
import Labs from "../containers/Labs";
import IngradinatSupplier from "../containers/IngradinatSuppliers";
import ConsumerSolution from "../containers/ConsumerSolution";
import DocumentProtection from "../containers/DocumentProtection";
import VerifyADocument from "../containers/VerifyADocument";
import ProductIntegrity from "../containers/ProductIntegrity";
import ComplianceDashboard from "../containers/ComplianceDashboard";
import NewHomePageDesign from "../containers/NewHomePageDesign";

const Routes = ({ getUserDetails, userData }) => {
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname?.split("/")[1];
  const { auth_token, otpVerified } = utils.getLocalStorageItems();
  useEffect(() => {
    if (
      utils.isProtectedRoutes(location.pathname) &&
      auth_token &&
      otpVerified
    ) {
      // otpVerified == true
      getUserDetails();
    } else if (utils.isAuthRoutes(location.pathname)) {
      if (auth_token && otpVerified == true) {
        history.push("/connections");
      }
      if (auth_token && !otpVerified) {
        history.push("/verify-otp");
      }
    }
  }, [path]);
  useEffect(() => {
    // console.log("===auth_token", auth_token);
    // console.log("===otpVerified", otpVerified);
    // console.log("===userData", userData);

    if (utils.isProtectedRoutes(location.pathname)) {
      if (!(auth_token && otpVerified)) {
        history.push("/profile-login");
      } else if (
        userData?.loading === false &&
        userData?.status !== "" &&
        userData?.status !== "1"
      ) {
        history.push("/profile-login");
        utils.removeItemsFromLocalStorage(["auth_token", "otpVerified"]);
        window.sessionStorage.clear();
      }
    } else if (utils.isAuthRoutes(location.pathname)) {
      if (auth_token && otpVerified == true) {
        history.push("/connections");
      }
    }
  }, [userData]);
  return (
    <>
      <ScrollToTop />
      <Socket />
      <Switch>
        {/* Profile Routes */}
        <Route
          path={"/product-feedback/:organization_id/:integrant_id"}
          exact
          component={Profile}
        />
        <Route path={"/create-profile"} exact component={Profile} />
        <Route path={"/create-profile/:token"} exact component={Profile} />
        <Route path={"/profile-signup"} exact component={Profile} />
        <Route path={"/profile-login"} exact component={Profile} />
        <Route path={"/profile"} exact component={Profile} />
        <Route path={"/profile-details"} exact component={Profile} />
        <Route path={"/offers"} exact component={Profile} />
        <Route path={"/survey"} exact component={Profile} />
        <Route path={"/survey/:token"} exact component={Profile} />
        <Route path={"/survey-history"} exact component={Profile} />
        <Route path={"/posts"} exact component={Profile} />
        <Route path={"/posts/:action"} exact component={Profile} />
        <Route path={"/connections"} exact component={Profile} />
        <Route path={"/feedback"} exact component={Profile} />
        <Route path={"/invite"} exact component={Profile} />
        <Route path={"/create-password/:token"} exact component={Profile} />
        <Route path={"/reset-password/:token"} exact component={Profile} />
        <Route path={"/forgot-password"} exact component={Profile} />
        <Route path={"/verify-otp"} exact component={Profile} />
        <Route path="/" exact component={NewHomePageDesign} />
        <Route path="/document-protection" component={DocumentProtection} />
        <Route path="/contact-us" exact component={ContactUs} />
        <Route path="/register" exact component={Signup} />
        <Route path="/login" exact component={Login} />
        <Route
          path="/organization-detail/:id"
          exact
          component={OrganizationDetail}
        />
        <Route
          path="/p/:short_code/b/:external_id"
          exact
          component={ProductDetailV5}
        />
        <Route path="/products" exact component={ProductList} />
        <Route path="/products/:productsId" exact component={ProductList} />
        <Route path="/error404" exact component={PageNotFound} />
        <Route
          path="/terms-and-conditions"
          exact
          component={TermsAndConditions}
        />
        <Route
          path="/subscription-cancellation"
          exact
          component={SubscriptionCancellation}
        />
        <Route path="/privacy" exact component={Privacy} />
        <Route path="/about-us" exact component={Affiliate} />
        <Route path="/partners" exact component={Partners} />
        <Route path="/track-and-trace" exact component={TrackAndTrace} />
        <Route path="/blog-news/:type" exact component={BlogNews} />
        <Route path="/blog-news/d/:detail" exact component={BlogNewsDetail} />
        <Route path="/user-detail/:user_id" exact component={Profile} />
        {/* New UI Routes */}
        <Route
          path="/counterfeit-prevention"
          exact
          component={CounterfeitPrevention}
        />
        <Route
          path="/document-verification"
          exact
          component={DocumentVerification}
        />
        <Route path="/document-protection" exact component={Solutions} />
        <Route
          path="/consumer-connection"
          exact
          component={ConsumerConnection}
        />
        <Route path="/label-validation" exact component={LabelLegitimization} />
        <Route path="/product-origin" exact component={ProductOrigin} />
        <Route path="/circular-economy" exact component={CircularEconomy} />
        <Route path="/retailers" exact component={Retailers} />
        <Route path="/verify-document" exact component={VerifyADocument} />
        <Route
          path="/manufacturer-brands"
          exact
          component={ManufacturerBrands}
        />
        <Route path="/labs" exact component={Labs} />
        <Route
          path="/ingredient-supplier"
          exact
          component={IngradinatSupplier}
        />
        <Route path="/consumer" exact component={ConsumerSolution} />
        <Route
          path="/product-integrity-program"
          exact
          component={ProductIntegrity}
        />
        <Route
          path="/compliance-dashboard"
          exact
          component={ComplianceDashboard}
        />
        {/* <Route path="/new-home-page" exact component={NewHomePageDesign} /> */}
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

const mapStateToProps = ({ ProfileReducers: { userData } }) => ({ userData });

const mapDispatchToProps = { getUserDetails };

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
