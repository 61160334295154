import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import appImages from "../../constants/AppImage";

const { newHomePageDesignImage } = appImages;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    minHeight: "200px",
    width: "100%",
    flexDirection: "column",
    paddingLeft: "5%",
    paddingRight: "5%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2%",
      paddingRight: "2%",
    },
  },
  "@keyframes moveBackground": {
    "0%": { backgroundPositionY: "0%" },
    "100%": { backgroundPositionY: "100%" },
  },
  title: {
    fontSize: "10vw",
    fontWeight: "900",
    textTransform: "uppercase",
    textAlign: "center",
    color: "transparent",
    backgroundImage: `url(${newHomePageDesignImage})`,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    lineHeight: 1.2,
    margin: 0,
    backgroundSize: "cover",
    backgroundRepeat: "repeat-y",
    backgroundPosition: "bottom center",
    animation: "$moveBackground 5s linear infinite",
    fontFamily : "fantasy",
    [theme.breakpoints.down("md")]: {
      fontSize: "12vw",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16vw",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20vw",
    },
  },
}));

const UnToldStories = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>untold</h1>
      <h1 className={classes.title}>stories</h1>
    </div>
  );
};

export default UnToldStories;
