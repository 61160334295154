import { PageWrapper } from "../../components/Wrappers";
import HowItWorksValidDocuments from "../../components/DocumentProtection/HowItWorksValidDocuments";
import ValidDocumentExample from "../../components/DocumentProtection/ValidDocumentExample";
import HowItWorksInvalidDocuments from "../../components/DocumentProtection/HowItWorksInvalidDocuments";
import FraudulentDocumentExample from "../../components/DocumentProtection/FraudulentDocumentExample";
import BulkDocumentProtection from "../../components/DocumentProtection/BulkDocumentProtection";
import HealthLoqSection from "../../components/DocumentProtection/HealthloqSection";
import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import appImages from "../../constants/AppImage";
import { Button, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clsx from "clsx";
const {
  headerImages: { documentProtection },
} = appImages;
const useStyle = makeStyles((theme) => ({
  grma_section: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    padding: "0px 25px",
  },
  grma_section_container: {
    background: "#F3F8EB",
    padding: 10,
    // margin: "0px 0px",
    marginTop: "10px",
  },
  grma_text: {
    width: "100%",
    fontSize: "24px",
    fontWeight: 600,
    padding: 10,
  },
}));
export default function DocumentProtection() {
  const classes = useStyle();
  return (
    <PageWrapper pageName="document-protection">
      {/* <Grid className={classes.grma_section_container}>
        <Grid className={clsx(classes.grma_section, "bodyMaxWidth")}>
          <Typography className={classes.grma_text}>
            HealthLOQ Announces Partnership with Global Retailer and Manufacturer Alliance (GRMA) to provide document protection and verification for Certificates of Analysis.
            <Link
              style={{
                color: "#28A745",
                marginLeft: "15px",
                wordBreak: "break-word",
              }}
              href="https://grmalliance.org/news/global-retailer-and-manufacturer-alliance-grma-announces-strategic-partnerships-with-healthloq-and-anab/"
              target="_blank"
            >
              <span>(more...)</span>
            </Link>
          </Typography>
        </Grid>
      </Grid> */}
      <HeaderSection
        headerImgSrc={documentProtection}
        // headerText="Simple, Easy Document Protection without the document ever leaving your secure perimeter."
        headerText={
          <>
            Simple, Easy Document Protection without the document ever leaving your secure perimeter.
            {/* <Link
              style={{
                color: "#28A745",
                marginLeft: "15px",
                wordBreak: "break-word",
              }}
              href="https://grmalliance.org/news/global-retailer-and-manufacturer-alliance-grma-announces-strategic-partnerships-with-healthloq-and-anab/"
              target="_blank"
            >
              <span>(more...)</span>
            </Link><br />
            <Button endIcon={<ArrowForwardIcon />} style={{ marginTop: "10px" }} href="/product-integrity-program">Learn More</Button> */}
          </>
        }
        headerTextPosition="right"
        headerImgPosition="left"
      />
      <HowItWorksValidDocuments />
      <ValidDocumentExample />
      <HowItWorksInvalidDocuments />
      <FraudulentDocumentExample />
      <BulkDocumentProtection />
      <HealthLoqSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
