import { Box, Divider, Grid, makeStyles, Typography } from "@material-ui/core";

import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "100px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 50px",
    position: "relative",
    gap: "15px",
    "&:first-child": {
      borderRight: `2px solid ${theme.palette.primary.main}`,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      marginBottom: "-2px",
    },
    "&:nth-child(4)": {
      borderTop: `2px solid ${theme.palette.primary.main}`,
      borderLeft: `2px solid ${theme.palette.primary.main}`,
      marginLeft: "-2px",
    },
    "&>svg": {
      color: theme.palette.primary.main,
      fontSize: "50px",
    },
    "&>p": {
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      margin: "20px",
      "&:first-child": {
        marginBottom: "0",
        border: "none",
      },
      "&:nth-child(4)": {
        marginLeft: "0",
        border: "none",
      },
    },
  },
  divider: {
    minHeight: "2px",
    background: theme.palette.primary.main,
    width: "150px",
  },
}));

const HighLight = () => {
  const classes = useStyle();
  return (
    <>
      <Box className={classes.root}>
        <Grid container>
          {Array.from({ length: 4 }).map((item) => (
            <Grid item sm={12} md={6} className={classes.container}>
              <VerifiedUserIcon />
              <Typography variant="h3">Feature</Typography>
              <Divider className={classes.divider} />
              <Typography>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default HighLight;
