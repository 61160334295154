import { Box, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import appImages from "../../constants/AppImage";

const { logo } = appImages;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    "& > h4": {
      color: theme.palette.common.white,
      fontWeight: "bold",
      marginBottom: "25px",
    },
  },
  pointBox: {
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    transition: "all 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      transform: "translateY(-10px)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    },
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2, // Limits text to two lines
  },
  description: {
    color: "#666",
    fontSize: "0.9rem",
    "&>span": {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },
  gridContainer: {
    display: "flex",
    marginTop: "50px",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  logo: {
    margin: theme.spacing(2),
  },
}));

const data = [
  {
    title:
      "Global Retailer and Manufacturer Alliance (GRMA), ANAB, and HealthLOQ launch Product Integrity Program",
    link: "/blog-news/d/healthloq-announces-partnership-with-global-retailer-and-manufacturer-alliance-(grma)-in-harmonized-product-integrity-program",
  },
  {
    title: "NJ Labs bolsters Document Security with HealthLOQ",
    link: "/blog-news/d/healthloq-announces-strategic-partnership-with-nj-labs-to-bolster-document-security",
  },
  {
    title:
      "Gemini Pharmaceuticals forges groundbreaking partnership and sets new standard with HealthLOQ",
    link: "/blog-news/d/healthloq-and-gemini-pharmaceuticals-forge-groundbreaking-partnership-setting-a-new-industry-standard",
  },
  {
    title: "Alkemist Labs protects entire document repository with HealthLOQ",
    link: "https://www.nutraingredients-usa.com/Article/2024/01/29/alkemist-labs-expands-testing-capabilities-protects-coas-with-healthloq/?utm_source=newsletter_daily&utm_medium=email&utm_campaign=29-Jan-2024&cid=DM1116774&bid=311328922",
  },
];

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const Feature = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Grid container spacing={3} className={classes.gridContainer}>
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={3} key={item.title}>
            <Link
              href={item.link}
              style={{
                textDecoration: "none",
              }}
              target="_blank"
            >
              <Box className={classes.pointBox}>
                <img
                  src={logo}
                  alt="Logo"
                  className={classes.logo}
                  height={30}
                  width={30}
                />
                <Typography className={classes.title}>
                  {truncateText(item.title, 50)}
                </Typography>
                <Typography className={classes.description}>
                  Click to <span> Learn More </span>
                </Typography>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Feature;
